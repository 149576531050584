import svgSprite from './icons.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      unreadCnt: 0,
      defaultUrl: 'https://img6.auto.ria.com/images/nophoto/avatar-50x50.png',
      platformIcons: {
        ios: 'iosUrl',
        web: 'webUrl',
        android: 'androidUrl',
      },
    };
  },
  components: {
    svgSprite,
  },
  computed: {
    name() {
      return this.item.name || '';
    },
    chatUrl() {
      return this.item?.chatLink;
    },
    platformIcon() {
      return this.item?.platform && this.platformIcons[this.item.platform];
    },
    avatarUrl() {
      return this.item.avatar ? `https://cdn.riastatic.com/photos/${this.item.avatar}` : this.defaultUrl;
    },
    normalizedDate() {
      if (!this.item?.date) return '';

      const locale = this.langCode === 'ru' ? 'ru' : 'uk-UA';
      const date = new Date(this.item?.date);
      const textDate = new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long' }).format(date);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${textDate}, ${hours}:${minutes}`;
    },
    computedBtnText() {
      const msgCnt = this.item?.chat?.msgCnt;
      const unreadCnt = this.item?.chat?.unreadCnt;

      if (unreadCnt) return 'Відповісти';
      if (msgCnt) return 'Продовжити';
      return 'Написати';
    },
  },
  methods: {
    openChat() {
      window.chatRia.initChat(this.chatUrl);
      this.unreadCnt = 0;
      this._slonik({
        event_id: 354,
        action_block: 20,
        chat_position: 5,
      });
    },
  },

  mounted() {
    this.unreadCnt = this.item?.chat?.unreadCnt || 0;
  },
  i18n: {
    messages: {
      uk: {
        'Чат вимкнено': 'Вимкнено',
        Написати: 'Написати',
        Продовжити: 'Продовжити',
        Відповісти: 'Відповісти',
      },
      ru: {
        'Чат вимкнено': 'Выключен',
        Написати: 'Написать',
        Продовжити: 'Продолжить',
        Відповісти: 'Ответить',
      },
    },
  },
};
